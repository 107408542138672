.fruitbasket-block {
  border-style: none;
  border-radius: 4vmin;
  outline: .5vmin solid #000;
  margin: 15px;
  padding: 20px;
}

.fruitbasket-block h2 {
  color: var(--main-text-color);
  margin: 0 0 8px;
  padding: 0;
  font-size: 28px;
  font-weight: bold;
}

.fruitbasket-block p {
  color: #fffd;
  margin: 4px 0 0;
  padding: 0;
  font-weight: 400;
}

.fruitbasket-lower {
  flex-flow: row;
  display: flex;
}

.contact, .cool-people {
  flex: 1;
}

.link-grid {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.link-grid-item {
  width: 25%;
  max-width: 25%;
  height: 0;
  padding-bottom: 25%;
}

.link-grid-img-container {
  width: calc(100% - 8px);
  height: 0;
  padding-bottom: calc(100% - 8px);
  position: relative;
}

.link-grid-img {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 4px;
  left: 4px;
}

.link-grid-item-cover {
  opacity: 0;
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 4px;
  left: 4px;
}

.link-grid-item-cover:hover {
  opacity: .4;
}

@media only screen and (width <= 768px) {
  .fruitbasket-lower {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.4611b81a.css.map */
